import React, { useRef } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { formatDollar } from "../../global/formatDollar";
import { maskSensitiveData } from "../../global/maskSensitiveData";
import Logo from "../../logo.png";

const TransactionModal = ({
  transaction,
  formatTransactionType,
  closeModal,
}) => {
  const receiptRef = useRef();

  const handlePrintReceipt = () => {
    const printWindow = window.open("", "_blank");
    const printContent = receiptRef.current.innerHTML;

    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt ${transaction.transaction_reference}</title>
          <style>
            body { 
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
              margin: 0;
              padding: 30px 40px;
              color: #333;
              line-height: 1.6;
            }
            
            .receipt-container {
              max-width: 600px;
              margin: 0 auto;
              background: #fff;
            }

            .header-section {
              display: flex;
              justify-content: space-between;
              border-bottom: 2px solid #cf2e2e;
              padding-bottom: 25px;
              margin-bottom: 30px;
            }

            .bank-logo {
              height: 60px;
              margin-right: 20px;
            }

            .bank-info {
              flex-grow: 1;
            }

            .bank-name {
              font-size: 24px;
              font-weight: 700;
              color: #cf2e2e;
              margin-bottom: 5px;
            }

            .bank-details {
              font-size: 13px;
              color: #666;
              line-height: 1.4;
            }

            .receipt-meta {
              text-align: right;
            }

            .receipt-title {
              font-size: 28px;
              color: #cf2e2e;
              margin: 0 0 5px 0;
            }

            .receipt-number {
              font-size: 16px;
              color: #666;
            }

            .transaction-amount {
              text-align: center;
              margin: 30px 0;
              padding: 20px;
              background: #f9f9f9;
              border-radius: 4px;
            }

            .amount-label {
              font-size: 16px;
              color: #666;
              margin-bottom: 10px;
            }

            .amount-value {
              font-size: 32px;
              font-weight: 700;
              color: ${
                transaction.direction === "outgoing" ? "#cf2e2e" : "#2e8b57"
              };
            }

            .details-section {
              margin-bottom: 35px;
            }

            .section-title {
              font-size: 18px;
              color: #cf2e2e;
              margin-bottom: 15px;
              padding-bottom: 8px;
              border-bottom: 2px solid #eee;
            }

            .detail-item {
              margin-bottom: 12px;
              display: flex;
              justify-content: space-between;
            }

            .detail-label {
              font-weight: 600;
              color: #666;
              margin-right: 15px;
            }

            .status-badge {
              display: inline-block;
              padding: 4px 12px;
              border-radius: 20px;
              font-size: 14px;
              font-weight: 600;
              background: ${
                transaction.status === "completed" ? "#d4edda" : "#fff3cd"
              };
              color: ${
                transaction.status === "completed" ? "#155724" : "#856404"
              };
            }

            .footer-section {
              margin-top: 40px;
              padding-top: 20px;
              border-top: 2px solid #eee;
              text-align: center;
              color: #666;
              font-size: 14px;
            }
          </style>
        </head>
        <body>
          <div class="receipt-container">
            ${printContent}
          </div>
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              }
            }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div className={GlobalStyle.modal}>
      <div className={GlobalStyle.transactionModalContainer}>
        <h3 className={GlobalStyle.textPrimaryBold}>Transaction details</h3>
        <span className="text-lg text-gray-500 pt-2">
          {transaction.direction === "outgoing" ? "-" : "+"}{" "}
          {formatDollar(transaction.amount)}
        </span>
        <span className={GlobalStyle.status}>{transaction.status}</span>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Type:</span>
          <span>{formatTransactionType(transaction.transaction_type)}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Reference ID:</span>
          <span>{transaction.transaction_reference}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Date:</span>
          <span>{new Date(transaction.created_at).toLocaleDateString()}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Time:</span>
          <span>{new Date(transaction.created_at).toLocaleTimeString()}</span>
        </div>
        {transaction.transaction_type === "transfer" && (
          <div className={GlobalStyle.transactionModalColumn}>
            <span>From:</span>
            <span>{maskSensitiveData(transaction.senders_account)}</span>
          </div>
        )}
        {transaction.account_id !== null && transaction.card_id === null && (
          <>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Bank:</span>
              <span>{transaction.bank_name}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>To:</span>
              <span>{transaction.recipient_account}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Routing number:</span>
              <span>{transaction.routing_number}</span>
            </div>
          </>
        )}
        {transaction.description !== null && (
          <div className={GlobalStyle.transactionModalColumn}>
            <span>Description:</span>
            <span>{transaction.description}</span>
          </div>
        )}

        {/* Print Receipt Button */}
        <button
          onClick={handlePrintReceipt}
          className="text-primary text-xs"
        >
          Print Receipt
        </button>

        <button className={GlobalStyle.buttonFull} onClick={closeModal}>
          Close
        </button>
      </div>

      {/* Hidden Receipt Content for Printing */}
      <div style={{ display: "none" }} ref={receiptRef}>
        <div className="header-section">
          <div className="bank-info">
            <img src={Logo} alt="Bank Logo" className="bank-logo" />
            <div className="bank-name">CLYVERIS BANK</div>
            <div className="bank-details">
              Providing secure banking services worldwide
            </div>
          </div>

          <div className="receipt-meta">
            <h1 className="receipt-title">RECEIPT</h1>
            <div className="receipt-number">
              # {transaction.transaction_reference}
            </div>
          </div>
        </div>

        <div className="transaction-amount">
          <div className="amount-label">Transaction Amount</div>
          <div className="amount-value">
            {transaction.direction === "outgoing" ? "-" : "+"}{" "}
            {formatDollar(transaction.amount)}
          </div>
        </div>

        <div className="details-section">
          <h3 className="section-title">Transaction Details</h3>
          <div className="detail-item">
            <span className="detail-label">Status:</span>
            <span className="status-badge capitalize">
              {transaction.status}
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Type:</span>
            <span>{formatTransactionType(transaction.transaction_type)}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Date:</span>
            <span>{new Date(transaction.created_at).toLocaleDateString()}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Time:</span>
            <span>{new Date(transaction.created_at).toLocaleTimeString()}</span>
          </div>
        </div>

        {transaction.transaction_type === "transfer" && (
          <div className="details-section">
            <h3 className="section-title">Transfer Details</h3>
            <div className="detail-item">
              <span className="detail-label">From Account:</span>
              <span>{maskSensitiveData(transaction.senders_account)}</span>
            </div>
            {transaction.account_id !== null &&
              transaction.card_id === null && (
                <>
                  <div className="detail-item">
                    <span className="detail-label">Bank:</span>
                    <span>{transaction.bank_name}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">To Account:</span>
                    <span>
                      {maskSensitiveData(transaction.recipient_account)}
                    </span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Routing Number:</span>
                    <span>{transaction.routing_number}</span>
                  </div>
                </>
              )}
          </div>
        )}

        {transaction.description && (
          <div className="details-section">
            <h3 className="section-title">Description</h3>
            <div className="detail-item">
              <span>{transaction.description}</span>
            </div>
          </div>
        )}

        <div className="footer-section">
          Thank you for banking with Clyveris Bank
          <br />
          For any inquiries, please contact customer support
          <br />
          {new Date().toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
