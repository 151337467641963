import React, { useState } from "react";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { RiWechatLine, RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { RxCaretDown, RxCaretRight } from "react-icons/rx";
import { useSelector } from "react-redux";

const PrimaryNavbar = () => {
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openMobileSubmenu, setOpenMobileSubmenu] = useState(null);
  const [openMobileNestedMenu, setOpenMobileNestedMenu] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const user = useSelector((state) => state.user.profile);

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  const handleSubmenuMouseEnter = (submenu) => {
    setOpenSubmenu(submenu);
  };

  const handleSubmenuMouseLeave = () => {
    setOpenSubmenu(null);
  };

  const toggleMobileSubmenu = (menu) => {
    setOpenMobileSubmenu((prev) => (prev === menu ? null : menu));
    setOpenMobileNestedMenu(null);
  };

  const toggleMobileNestedMenu = (submenu) => {
    setOpenMobileNestedMenu((prev) => (prev === submenu ? null : submenu));
  };

  const navigation = [
    { name: "Home", path: "/" },
    {
      name: "Services",
      submenu: [
        {
          name: "Accounts",
          submenu: [
            { name: "All Accounts", path: "/accounts" },
            { name: "Checking Account", path: "/accounts/checking" },
            { name: "Savings Account", path: "/accounts/savings" },
          ],
        },
        {
          name: "Cards",
          submenu: [
            { name: "All Cards", path: "/cards" },
            { name: "Credit Card", path: "/cards/credit" },
            { name: "Debit Card", path: "/cards/debit" },
          ],
        },
        {
          name: "Loans",
          path: "/loans",
        },
      ],
    },
    {
      name: "About",
      submenu: [
        { name: "About Us", path: "/about-us" },
        { name: "Careers", path: "/careers" },
        { name: "FAQs", path: "/faqs" },
        { name: "Terms & Condition", path: "/terms-and-condition" },
        { name: "Privacy Policy", path: "/privacy-policy" },
      ],
    },
    {
      name: "Apply Now",
      submenu: [
        { name: "Home Loan", path: "/loans/home-loan" },
        { name: "Personal Loan", path: "/loans/personal-loan" },
        { name: "Educational Loan", path: "/loans/educational-loan" },
      ],
    },
  ];

  return (
    <nav className="w-full h-20 flex flex-col font-light fixed z-40 bg-white shadow-lg">
      <div className="w-full h-full px-5 lg:px-10 flex items-center justify-between relative">
        <Link to={"/"} className="flex items-center gap-2">
          <img src={logo} alt="Logo" className="w-10 h-10" />
          <span className="text-lg">Clyveris Bank</span>
        </Link>

        {/* Desktop Navigation */}
        <div className="h-full hidden lg:flex justify-center items-center relative">
          {navigation.map((menu, idx) => (
            <div
              key={idx}
              className="relative h-full group px-3"
              onMouseEnter={() => menu.submenu && handleMouseEnter(menu.name)}
              onMouseLeave={handleMouseLeave}
            >
              <Link
                to={menu.path || "#"}
                className="w-32 h-full text-gray-800 hover:text-primary transition-all ease-in-out duration-500 flex flex-col justify-center items-center"
              >
                <span>
                  {menu.name}
                  {menu.submenu && <RxCaretDown className="ml-1 inline" />}
                </span>
                <div className="absolute bottom-0 left-0 w-full h-1 bg-primary scale-x-0 group-hover:scale-x-100 transition-transform ease-in-out duration-500 origin-left"></div>
              </Link>

              {menu.submenu && hoveredMenu === menu.name && (
                <div className="absolute top-full left-0 bg-white border w-48 z-20 shadow-lg">
                  {menu.submenu.map((sub, idx) => (
                    <div
                      key={idx}
                      className="relative"
                      onMouseEnter={() => handleSubmenuMouseEnter(sub.name)}
                      onMouseLeave={handleSubmenuMouseLeave}
                    >
                      <Link
                        to={sub.path || "#"}
                        className="block px-4 py-2 hover:bg-primary hover:text-white transition-all ease-in-out duration-500"
                      >
                        {sub.name}{" "}
                        {sub.submenu && (
                          <RxCaretRight className="ml-1 inline" />
                        )}
                      </Link>
                      {sub.submenu && openSubmenu === sub.name && (
                        <div className="absolute left-full top-0 bg-white border w-48 z-30">
                          {sub.submenu.map((nested, idx) => (
                            <Link
                              key={idx}
                              to={nested.path}
                              className="block px-4 py-2 hover:bg-primary hover:text-white transition-all ease-in-out duration-500"
                            >
                              {nested.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Mobile Navigation */}
        <ul className="gap-3 items-center flex">
          {user ? (
            <Link
              to={"/online-banking/dashboard"}
              className="flex gap-1 items-center text-gray-500 hover:text-primary transition-all ease-in-out duration-500"
            >
              <FaRegUser /> <span>Dashboard</span>
            </Link>
          ) : (
            <Link
              to={"/login"}
              className="flex gap-1 items-center text-gray-500 hover:text-primary transition-all ease-in-out duration-500"
            >
              <FaRegUser /> <span>Sign in</span>
            </Link>
          )}
          <Link
            to={"/contact"}
            className="hidden lg:flex gap-1 items-center text-gray-500 hover:text-primary transition-all ease-in-out duration-500"
          >
            <RiWechatLine /> <span>Contact us</span>
          </Link>
          <div
            className="lg:hidden text-primary text-2xl cursor-pointer"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
              if (!mobileMenuOpen) {
                setOpenMobileSubmenu(null);
                setOpenMobileNestedMenu(null);
              }
            }}
          >
            {mobileMenuOpen ? <RiCloseLine /> : <RiMenu3Line />}
          </div>
        </ul>
      </div>

      {/* Mobile Dropdown */}
      <div
        className={`-z-10 lg:hidden top-20 absolute left-0 w-full bg-white flex flex-col transition-all ease-in-out duration-500 transform ${
          mobileMenuOpen
            ? "translate-y-0 opacity-100"
            : "-translate-y-full opacity-0"
        }`}
      >
        {navigation.map((menu, idx) => (
          <div key={idx} className="flex flex-col">
            <button
              className="px-5 py-3 border-b flex justify-between items-center"
              onClick={() =>
                menu.submenu
                  ? toggleMobileSubmenu(menu.name)
                  : setMobileMenuOpen(false)
              }
            >
              {menu.name}
              {menu.submenu && <RxCaretDown />}
            </button>
            {menu.submenu && openMobileSubmenu === menu.name && (
              <div className="flex flex-col bg-gray-50 transition-all duration-300 ease-in-out">
                {menu.submenu.map((sub, idx) => (
                  <div key={idx} className="flex flex-col">
                    <Link
                      to={sub.path}
                      className="px-10 py-2 hover:bg-gray-100 text-gray-700 flex justify-between items-center"
                      onClick={() =>
                        sub.submenu
                          ? toggleMobileNestedMenu(sub.name)
                          : setMobileMenuOpen(false)
                      }
                    >
                      {sub.name}
                      {sub.submenu && <RxCaretDown />}
                    </Link>
                    {sub.submenu && openMobileNestedMenu === sub.name && (
                      <div className="flex flex-col bg-gray-200">
                        {sub.submenu.map((nested, idx) => (
                          <Link
                            key={idx}
                            to={nested.path}
                            onClick={() => setMobileMenuOpen(false)}
                            className="px-12 py-2 text-gray-600 hover:bg-primary hover:text-white"
                          >
                            {nested.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </nav>
  );
};

export default PrimaryNavbar;
