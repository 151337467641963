import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import logo from "../../logo.png";

const LoadingScreen = () => {
  return (
    <div className="w-full bg-white dark:bg-slate-900 transition-all ease-in-out duration-500 h-screen flex flex-col justify-center items-center gap-8">
      <img src={logo} alt="Logo" className="w-24 h-24" />
      <LoadingSpinner />
    </div>
  );
};

export default LoadingScreen;
